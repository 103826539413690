import * as analytics from 'lib/analytics'
import createActionConstants from 'lib/create-action-constants'

//
// URL params to update on every search submit
//
const SEARCH_TEXT_UPDATES = {
  q: 'ui.search.term',
  page: 1
}

export const constants = createActionConstants('search-citations', [
  'SET_MODE',
  'SET_CITATION_TYPES',
  'SET_SORT',
  'SET_SELECTED_SECTIONS',
  'SET_YEAR_FILTER',
  'SET_JOURNAL_FILTER',
  'SET_AUTHOR_FILTER',
  'SET_AFFILIATION_FILTER',
  'SET_CITATION_RANGES_FILTER',
  'SET_HAS_TALLY_FILTER',
  'SET_AGGREGATION_FILTERS',
  'SET_MESH_TYPE_FILTER',
  'ADD_AGGREGATION_FILTERS',

  'NAV_SUBMIT',

  'TOGGLE_MOBILE_EXPANDED',

  'CLEAR_FILTERS',
  'CLEAR_FILTER',

  'SUBMIT_SEARCH',

  'RUN_SEARCH',

  'FETCH_RESULTS',
  'FETCH_RESULTS_SUCCESS',
  'FETCH_RESULTS_FAILURE',

  'FETCH_AGGREGATIONS',
  'FETCH_AGGREGATIONS_SUCCESS',
  'FETCH_AGGREGATIONS_FAILURE',

  'FETCH_MESH_TYPES',
  'FETCH_MESH_TYPES_SUCCESS',
  'FETCH_MESH_TYPES_FAILURE',

  'HANDLE_QUERY_CHANGE',

  'GENERATE_PDF_ANALYSIS',
  'GENERATE_PDF_ANALYSIS_SUCCESS',
  'GENERATE_PDF_ANALYSIS_FAILURE',

  'FETCH_PDF_ANALYSIS',
  'FETCH_PDF_ANALYSIS_SUCCESS',
  'FETCH_PDF_ANALYSIS_FAILURE',

  'EXPORT_SEARCH_FETCHING',
  'EXPORT_SEARCH_SUCCESS',
  'EXPORT_SEARCH_FAILURE',
  'CLEAR_EXPORT_SEARCH_ERROR',

  'FETCH_SEARCH_HISTORY',
  'FETCH_SEARCH_HISTORY_SUCCESS',
  'FETCH_SEARCH_HISTORY_FAILURE',
  'DELETE_SEARCH_HISTORY',
  'DELETE_SEARCH_HISTORY_SUCCESS',
  'DELETE_SEARCH_HISTORY_FAILURE'
])

export const fetchSearchHistory = ({ slug }) => ({
  type: constants.FETCH_SEARCH_HISTORY,
  payload: { slug }
})

export const deleteSearchHistory = ({ id, slug }) => ({
  type: constants.DELETE_SEARCH_HISTORY,
  payload: {
    id,
    slug
  }
})

export const setMode = mode => ({
  type: constants.SET_MODE,
  payload: mode,
  meta: {
    queryUpdate: {
      update: {
        ...SEARCH_TEXT_UPDATES,
        mode: 'search.mode',
        sections: 'search.sections',
        citationTypes: 'search.citationTypes'
      },
      push: true
    }
  }
})

export const generatePdfAnalysis = () => ({
  type: constants.GENERATE_PDF_ANALYSIS
})

export const fetchPdfAnalysis = () => ({
  type: constants.FETCH_PDF_ANALYSIS
})

export const setCitationTypes = types => ({
  type: constants.SET_CITATION_TYPES,
  payload: types,
  meta: {
    queryUpdate: {
      update: {
        citationTypes: 'search.citationTypes',
        page: 'search.currentPage'
      },
      push: true
    }
  }
})

export const setSections = sections => ({
  type: constants.SET_SELECTED_SECTIONS,
  payload: sections,
  meta: {
    queryUpdate: {
      update: {
        sections: 'search.sections',
        page: 'search.currentPage'
      },
      push: true
    }
  }
})

export const setSort = type => ({
  type: constants.SET_SORT,
  payload: type,
  meta: {
    queryUpdate: {
      update: {
        sortOn: 'search.sort.value.key',
        sortOrder: 'search.sort.value.order',
        page: 'search.currentPage'
      },
      push: true
    }
  }
})

export const setYearFilter = yearFilter => ({
  type: constants.SET_YEAR_FILTER,
  payload: yearFilter,
  meta: {
    queryUpdate: {
      update: {
        yearFrom: 'search.years.from',
        yearTo: 'search.years.to',
        page: 'search.currentPage'
      }
    }
  }
})

export const setJournalFilter = journal => ({
  type: constants.SET_JOURNAL_FILTER,
  payload: journal,
  meta: {
    queryUpdate: {
      update: {
        journal: 'search.journal',
        page: 'search.currentPage'
      }
    }
  }
})

export const setAuthorFilter = author => ({
  type: constants.SET_AUTHOR_FILTER,
  payload: author,
  meta: {
    queryUpdate: {
      update: {
        author: 'search.author',
        page: 'search.currentPage'
      }
    }
  }
})

export const setAffiliationFilter = affiliation => ({
  type: constants.SET_AFFILIATION_FILTER,
  payload: affiliation,
  meta: {
    queryUpdate: {
      update: {
        affiliation: 'search.affiliation',
        page: 'search.currentPage'
      }
    }
  }
})

export const setCitationRangesFilter = ranges => ({
  type: constants.SET_CITATION_RANGES_FILTER,
  payload: ranges,
  meta: {
    queryUpdate: {
      update: {
        q: 'ui.search.term',
        supportingFrom: 'search.citationRanges.supporting.min',
        supportingTo: 'search.citationRanges.supporting.max',
        mentioningFrom: 'search.citationRanges.mentioning.min',
        mentioningTo: 'search.citationRanges.mentioning.max',
        contrastingFrom: 'search.citationRanges.contrasting.min',
        contrastingTo: 'search.citationRanges.contrasting.max',
        page: 'search.currentPage'
      }
    }
  }
})

export const setAggregationFilters = filters => ({
  type: constants.SET_AGGREGATION_FILTERS,
  payload: filters,
  meta: {
    queryUpdate: {
      update: Object.keys(filters)
        .reduce((acc, filterName) => ({
          ...acc,
          [filterName]: `search.aggregationFilters.${filterName}`
        }), {
          ...SEARCH_TEXT_UPDATES,
          page: 'search.currentPage'
        })
    }
  }
})

export const addAggregationFilters = filters => ({
  type: constants.ADD_AGGREGATION_FILTERS,
  payload: filters
})

export const setHasTallyFilter = hasCites => ({
  type: constants.SET_HAS_TALLY_FILTER,
  payload: hasCites,
  meta: {
    queryUpdate: {
      update: {
        ...SEARCH_TEXT_UPDATES,
        page: 'search.currentPage',
        hasTally: 'search.hasTally'
      }
    }
  }
})

export const submitSearch = term => {
  analytics.event({
    category: 'Search Citations',
    action: 'searched',
    label: term
  })

  return {
    type: constants.SUBMIT_SEARCH,
    meta: {
      queryUpdate: {
        update: {
          q: 'ui.search.term',
          page: 'search.currentPage'
        },
        push: true
      }
    }
  }
}

export const clearFilters = () => ({
  type: constants.CLEAR_FILTERS,
  meta: {
    queryUpdate: {
      update: {
        q: 'ui.search.term',
        mode: 'search.mode'
      },
      push: true,
      prune: true
    }
  }
})

export const clearFilter = key => ({
  type: constants.CLEAR_FILTER,
  payload: key,
  meta: {
    queryUpdate: {
      update: {
        [key]: `search.${key}`
      }
    }
  }
})

export const toggleMobileExpanded = () => ({
  type: constants.TOGGLE_MOBILE_EXPANDED
})

export const handleQueryChange = query => ({
  type: constants.HANDLE_QUERY_CHANGE,
  payload: query
})

export const runSearch = (params, key = null) => ({
  type: constants.RUN_SEARCH,
  payload: { params, key }
})

export const navSubmit = () => ({
  type: constants.NAV_SUBMIT,
  meta: {
    queryUpdate: {
      push: true,
      location: {
        ...window.location,
        pathname: '/search'
      },
      update: {
        ...SEARCH_TEXT_UPDATES,
        mode: 'search.mode'
      }
    }
  }
})

export const exportSearchResults = (sessionID, exportResultSelection, exportResultFormat) => ({
  type: constants.EXPORT_SEARCH_FETCHING,
  payload: { sessionID, exportResultSelection, exportResultFormat }
})

export const clearExportingSearchError = () => ({
  type: constants.CLEAR_EXPORT_SEARCH_ERROR
})

export const fetchMeshTypes = descriptorId => ({
  type: constants.FETCH_MESH_TYPES,
  payload: descriptorId
})

export const setMeshTypeFilter = meshType => ({
  type: constants.SET_MESH_TYPE_FILTER,
  payload: meshType,
  meta: {
    queryUpdate: {
      update: {
        meshType: 'search.meshType',
        page: 'search.currentPage'
      }
    }
  }
})
