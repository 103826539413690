const PLANS = {
  free: 'free',
  premium: 'premium',
  organizational: 'organizational'
}

const SUBSCRIPTION_STATUS = {
  inactive: 'inactive',
  active: 'active',
  trialing: 'trialing',
  paymentFailed: 'payment_failed',
  paused: 'paused'
}

const STRIPE_SUBSCRIPTION_STATUS = {
  trialing: 'trialing',
  active: 'active',

  // Can happen after a failed payment or during payment grace period
  incomplete: 'incomplete',

  // After a failed payment
  past_due: 'past_due',

  // Non-trial expires, where retries don't occur, perhaps within the expired retry
  // window of another subscription (trial)
  incomplete_expired: 'incomplete_expired',

  // a. Subscription was superceded by another
  // b. What happens when past-due retries run out
  canceled: 'canceled',

  // We don't use this. Instead, failed retries lead to canceled
  unpaid: 'unpaid',

  // We don't use this either. We support use of the pause_collection attribute but
  // that's different/unrelated to this status
  paused: 'paused'
}

// These indicate a user has an account
// sometimes they will have an account from the past
// and are just signing in which is ok.
const SIGNUP_ACTIONS = {
  CREATED_ACCOUNT: 'Created an Account',

  LOGGED_IN_LOCAL: 'Logged in',

  LOGGED_IN_ORCID: 'Logged in - Orcid',
  SIGNED_UP_ORCID: 'Signed up - Orcid',

  LOGGED_IN_FACEBOOK: 'Logged in - Facebook',
  SIGNED_UP_FACEBOOK: 'Signed up - Facebook',

  LOGGED_IN_GOOGLE: 'Logged in - Google',
  SIGNED_UP_GOOGLE: 'Signed up - Google',

  LOGGED_IN_SAML_SSO: 'Logged in - Saml SSO',
  SIGNED_UP_SAML_SSO: 'Signed up - Saml SSO',

  LOGGED_IN_AZURE: 'Logged in - Azure',
  SIGNED_UP_AZURE: 'Signed up - Azure',

  LOGGED_IN_OPEN_ATHENS: 'Logged in - OpenAthens',
  SIGNED_UP_OPEN_ATHENS: 'Signed up - OpenAthens'
}

module.exports = {
  PLANS,
  SUBSCRIPTION_STATUS,
  STRIPE_SUBSCRIPTION_STATUS,
  SIGNUP_ACTIONS
}
